
const outlineImg = require('@/static/box-outline.svg')
const highlightImg = require('@/static/box-outline-highlight.svg')
export default {
  name: 'CheckBox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: String,
    highlight: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    value(newVal, oldVal) {
      console.log("whater: ", newVal);
    }
  },
  data() {
    return {
      outlineImg,
      highlightImg,
    }
  },
  methods: {
    onChecked() {
      this.$emit('input', !this.value)
    },
  },
}
