import { render, staticRenderFns } from "./UserPermissionPopup.vue?vue&type=template&id=6c1897a7&scoped=true"
import script from "./UserPermissionPopup.vue?vue&type=script&lang=js"
export * from "./UserPermissionPopup.vue?vue&type=script&lang=js"
import style0 from "./UserPermissionPopup.vue?vue&type=style&index=0&id=6c1897a7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c1897a7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CheckBox: require('/opt/render/project/src/components/CheckBox.vue').default,GradientButton: require('/opt/render/project/src/components/GradientButton.vue').default})
