// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/rectangle.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scroll-bar[data-v-11bea213]::-webkit-scrollbar{width:8px}.scroll-bar[data-v-11bea213]::-webkit-scrollbar-thumb{background-color:#95a2b5;background-color:var(--nn3,#95a2b5);border-radius:5px}.scroll-bar[data-v-11bea213]::-webkit-scrollbar-track{background-color:#1e1e23;background-color:var(--nn1,#1e1e23)}.popup[data-v-11bea213]{-webkit-backdrop-filter:blur(2px);backdrop-filter:blur(2px);background:#d3d3d3 0 0 100px repeat,linear-gradient(210deg,#404040,#161618 55.73%)}.popup[data-v-11bea213],.popup-parent-dark[data-v-11bea213]{border:1px solid #45454d;border-radius:16px}.popup-parent-dark[data-v-11bea213]{-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.popup-parent-light[data-v-11bea213]{background:#fff;border:1px solid #45454d;border-radius:16px;box-shadow:0 20px 24px -4px rgba(0,0,0,.2),0 8px 8px -4px rgba(0,0,0,.03)}.popup-container[data-v-11bea213]{background:linear-gradient(210deg,#404040,transparent 56%);border-radius:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
