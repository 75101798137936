
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js'

export default ({ app }, inject) => {

  // 实例化 markdown-it
  const md = new MarkdownIt({
    html: true,
    // xhtmlOut: true,
    // breaks: true,
    // linkify: true,
    typographer: true,
    // quotes: '“”‘’',
    // 将 '-' 解析成小圆点
    listItemIndent: '1em',
    // 设置缩进的字符
    bulletIndent: '1em',
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return '<pre><code class="hljs">' +
                 hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
                 '</code></pre>';
        } catch (__) {}
      }
  
      return '<pre><code class="hljs">' + md.utils.escapeHtml(str) + '</code></pre>';
    },
  });
  md.linkify.set({ fuzzyEmail: false });
  
  md.renderer.rules.list_item_open = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    // console.log(tokens[idx], idx, options, env, self);
    // const styleAttr = token.attrGet('style');
    // console.log("style:", styleAttr);
    // if (styleAttr) {
    //   if (styleAttr.includes('disc')) {
    //     console.log('样式类型：小圆点');
    //   } else if (styleAttr.includes('circle')) {
    //     console.log('样式类型：空心圆');
    //   } else if (styleAttr.includes('decimal')) {
    //     console.log('样式类型：数字');
    //   }
    // }
    return `<li${self.renderAttrs(token)}>`;
  };
  md.renderer.rules.list_item_close = (tokens, idx, options, env, self) => {
    // const token = tokens[idx];
    // console.log(tokens[idx], idx, options, env, self);
    return '';
  };



   // 添加处理器，在解析器阶段获取原始文本值并根据其设置样式类型
   md.core.ruler.push('custom_list_item_open', (state) => {
    let listItemStart = 0;
    let starToken = null;
    state.tokens.forEach((token, idx) => {
      if (token.type === 'list_item_open') {
        const content = state.tokens[idx - 3].content
        listItemStart = state.src.indexOf(content) + content.length
        starToken = token;
        // console.log("star:",content,", index:", listItemStart);
        // listItemStart = idx;
      } else if (token.type === 'list_item_close') {
        const content = state.tokens[idx - 2].content
        const listItemEnd = state.src.indexOf(content)
        // console.log("close:",content,", index:", listItemEnd);
        const listItemContent = state.src.slice(listItemStart + 1, listItemEnd).trim(); // 获取列表项的原始内容
        let style = '';
        // console.log("token:", token,",", idx,",listItemContent:", listItemContent, ",contains:", /\d+\.\s/.test(listItemContent));
        if (listItemContent.includes('.')) {
          style += 'list-style-type: decimal;';
        } else {
          style += 'list-style-type: disc;';
        }
        style += 'margin-left: 3em;';
        if(starToken)starToken.attrSet('style', style);
      }
    });
    return false;
  });



   // 将列表项的 bullet 设置为 '-'
  //  md.options.bullet = '- ';

  //  md.renderer.rules.list_item_open = (tokens, idx, options, env, self) => {
  //   tokens[idx].attrPush(['style', 'margin-left: 1em;lis-style-type:disc']);
  //   return self.renderToken(tokens, idx, options);
  // };
  // md.renderer.rules.list_item_open = (tokens, idx, options, env, self) => {
  //   const token = tokens[idx];
  //   console.log("token:", token);
  //   const indent = '2em'; // 设置缩进的距离
  //   token.attrSet('style', `list-style-type: disc; margin-left: ${indent};`);
  //   return self.renderToken(tokens, idx, options);
  // };
  // 自定义渲染规则，仅将以 '- ' 开头的行解析为列表项，并添加小圆点样式
  // md.core.ruler.push('custom_list_item_open', (state) => {
  //   state.tokens.forEach((token, idx) => {
  //     console.log("token:", token, ",",  state.tokens[idx + 1]);
  //     if (token.type === 'list_item_open') {
  //       const content = state.tokens[idx + 1].children[0].content.trim(); // 获取列表项的内容
  //       const isDash = content.startsWith('- ');
  //       const isNumber = /^\d+\.\s/.test(content);
  //       if (isDash || isNumber) {
  //         let style = '';
  //         if (isDash) style += 'list-style-type: disc;';
  //         else if (isNumber) style += 'list-style-type: decimal;';
  //         style += 'margin-left: 1em;';
  //         token.attrSet('style', style);
  //       }
  //     }
  //   });
  //   return false;
  // });
  
  // 将 markdown-it 实例注入到 Vue 实例中
  inject('md', md);
};