

export default {
  data() {
    return {
      systemBanner: null,
    };
  },
  mounted() {
    const theme = localStorage.getItem('theme') || 'dark'
    this.$store.commit('setTheme', theme)
    const banner = this.$store.state.systemBanner || localStorage.getItem('systemBanner')
    this.systemBanner = banner;
    this.$nextTick(()=> {
      this.changeTheme(theme);
    });
  },
  methods: {
    changeTheme(theme) {
        const dark = {
          '--nn0': '#161618',
          '--nn1': '#1E1E23',
          '--nn2': '#2F374B',
          '--nn3': '#95A2B5',
          '--nn4': '#E0E7EC',
          '--nn5': '#F6FAFF',
          '--nn6': '#FFFFFF'
        }
        const light = {
          '--nn0': '#FFFFFF',
          '--nn1': '#F6FAFF',
          '--nn2': '#E0E7EC',
          '--nn3': '#95A2B5',
          '--nn4': '#2F374B',
          '--nn5': '#1E1E23',
          '--nn6': '#161618'
        }
        this.$nextTick(()=>{
          if(theme === 'light') {
            Object.keys(light).forEach((key)=>{
              document.documentElement.style.setProperty(key, light[key])
            })
          } else {
            Object.keys(dark).forEach((key)=>{
              document.documentElement.style.setProperty(key, dark[key])
            })
          }
        });
    }
  }

}
