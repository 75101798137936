
import GradientBackground from '@/components/GradientBackground.vue'
import GradientButton from '@/components/GradientButton.vue'
import OutlineButton from '@/components/OutlineButton.vue'
export default {
  name: "EmoexPopup",
  components: { GradientBackground, GradientButton, OutlineButton },
  props: {
    visible: Boolean,
    negativeText: {
      type: String,
      default: ''
    },
    positiveText: {
      type: String,
      default: ''
    },
    positiveButtonType: {
      type: String,
      default: "pink"
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    wf:{
      type: String,
      default: 'w-1/2'
    }
  },
  data() {
    return {
        invisible: true,
    }
  },
  computed: {
    isDark() {
      return this.$store.state.theme === 'dark'
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.invisible = !newVal
      }
    }
  },
  methods: {
    onPositive() {
        if(this.autoClose)this.close()
        this.$emit("popup:positive")
    },
    onNegative() {
        if(this.autoClose)this.close()
        this.$emit("popup:negative")
    },
    close() {
        this.invisible = true;
        this.$emit("popup:close")
    }
  }
}
