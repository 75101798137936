
import EmoexPopup from '@/components/EmoexPopup.vue'
export default {
  name: 'PaymentSucceedPop',
  components: { EmoexPopup },
  props: ['value', 'plan'],
  methods: {
    formatPrice(str) {
      const formattedStr = (parseInt(`${str}`)/1000).toLocaleString('en-US', {
        maximumFractionDigits: 0, // 不显示小数点和小数位
        thousandsSeparator: ',' // 使用逗号作为千位分隔符
      });
      return formattedStr;
    },
    onDeletePopClose() {
       this.$emit('input')
    },
    onDeleteNegative() {
       this.$emit('negative'); 
    },
    onDeletePositive() {
       this.$emit('positive');
    },
  }
}
