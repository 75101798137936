
import GradientButton from '../GradientButton.vue'
import BasePopup from './BasePopup.vue'

export default {
  name: 'HitFreeLimitPopup',
  components: { BasePopup, GradientButton },
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  methods: {
    onClosePopup() {
      this.$emit('input')
    },
    onSignIn() {
      this.$router.push({path: '/account/SignUp', query: {tab: 1}})  
    },
    onSignUp() {
      this.$router.push({path: '/account/SignUp', query: {tab: 0}})    
    }
  },
}
