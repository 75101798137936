
export default {
  name: 'DropPannel',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
      // console.log('expanded:', this.expanded)
    },
    close(){
      this.expanded = false;
    }
  },
}
