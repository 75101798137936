
export default {
  name: 'EmoexInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    value: String,
    error: String,
    id: String,
    name: String, 
    autocomplete: String
  },
  data() {
    return {
      visible: false,
      text: '',
      readonlyInput: true
    }
  },
  mounted() {
    this.visible = this.type !== 'password'
  },
  methods: {
    onFocus(){
      this.readonlyInput = false;
    },
    changeVisible() {
      this.visible = !this.visible
    },
    onChanged(e) {
      this.$emit('input', e.target.value)
    },
    onBlur(e) {
      this.$emit('blur')
    }
  },
}
