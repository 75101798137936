
import DropPannel from '@/components/DropPannel.vue'
import GradientButton from '@/components/GradientButton.vue'
import BasePopup from '@/components/popup/BasePopup.vue'

export default {
  name: 'CharacterDetailPopup',
  components: { BasePopup, GradientButton, DropPannel },
  props: ['value', 'character', 'maxHeight'],
  data() {
    return {
      list: [
        'Unwavering companionship, providing a sense of belonging and connection. ',
        'A listening ear, offering you space to share your thoughts and feelings without judgment.',
        'Essential advice on managing emotions,helping you find balance and peace of mind.'
      ]
    }
  },
  computed: {
    isDark() {
      return this.$store.state.theme === 'dark'
    },
    tags() {
      return (this.character && this.character.tags && this.character.tags.split(',') || []).filter(e => e.length)
    },
    expects() {
      return (this.character && this.character.text2 && this.character.text2.split('· ') || []).filter(e => e.length)
    },
    limitations() {
      return (this.character && this.character.text3 && this.character.text3.split('· ') || []).filter(e => e.length)
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        // console.log("close drop pannel");
        this.$nextTick(() => {
          this.$refs.expects.close()
          this.$refs.limitations.close()
        })
      }
    }
  },
  methods: {
    onClosePopup() {
      this.$emit('input')
    },
    onChatClick() {
      this.$emit('chat')
      this.onClosePopup()
    }
  }
}
