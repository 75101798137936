
export default {
  name: 'DividerLine',
  props: {
    text: String,
    type: {
      type: String,
      required: false,
      default: undefined,
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dividerLineClass() {
      return {
        'highlight-bg highlight-text': this.type === "pro",
        'wellbeing-bg highlight-text': this.type === "wellbeing",
        'default-bg default-text': !this.type,
      }
    }
  }
}
