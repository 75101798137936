
// import BusinessUserPopup from '~/components/popup/BusinessUserPopup.vue'
import PaymentCanceledPopup from '~/components/popup/PaymentCanceledPopup.vue'
import PaymentSucceedPop from '~/components/popup/PaymentSucceedPop.vue'
import TitleBar from '@/components/TitleBar.vue'
import GradientButton from '@/components/GradientButton.vue'
import ExpandableItem from '@/components/ExpandableItem.vue'
import MemberPlans from '@/components/MemberPlans.vue'
import RedeemSuccessPopup from '@/components/popup/RedeemSuccessPopup.vue'
import RedeemCompanyConflictWraningPopup from '@/components/popup/RedeemCompanyConflictWraningPopup.vue'
import RedeemAlreadyUsedWraningPopup from '@/components/popup/RedeemAlreadyUsedWraningPopup.vue'
import RedeemInvalidCodeWraningPopup from '@/components/popup/RedeemInvalidCodeWraningPopup.vue'
import RedeemNotMatchCharacterWraningPopup from '@/components/popup/RedeemNotMatchCharacterWraningPopup.vue'
// import DividerLine from '@/components/DividerLine.vue'
export default {
  name: 'ProCharacterDetail',
  components: {
    TitleBar, GradientButton, MemberPlans,
    RedeemSuccessPopup, RedeemInvalidCodeWraningPopup, RedeemAlreadyUsedWraningPopup,
    PaymentCanceledPopup, PaymentSucceedPop,
    RedeemNotMatchCharacterWraningPopup,
    RedeemCompanyConflictWraningPopup,
    ExpandableItem
  },
  data() {
    return {
      width: 0,
      redeemCode: '',
      showBusinessDialog: false,
      business: null,
      showAlreayInUseDialog: false,
      showInvalidCodeDialog: false,
      showNotMatchDialog: false,
      showCompanyConflictDialog: false,
      showPaySuccessPopup: false,
      showPayCancelPopup: false,
      payPlan: null,
      paidCharacter: null,
      expectName: '',
      textTitle1: '',
      text1: '',
      textTitle2: '',
      text2: '',
      textTitle3: '',
      text3: ''
    }
  },
  computed: {
    showLetsChatBtn() {
      return this.subscribed && this.$store.state.character.id !== this.character.id
    },
    subscribed() {
      const character = this.character
      return character && character.own
    },
    character() {
      const { params: {slug}, query } = this.$route
      let id;
      if (slug) {
        id = slug
      } else {
        id = this.$route.query.id
      }
      const characterList = this.$store.state.characters || []
      if (!id) {
        return null
      }
      const c = characterList.find((e) => e.id === id)
      if (query.selected === 'true' && !!c) {
        this.$store.commit('setCharacter', c)
      }
      return c;
    },
    expiredAt() {
      const character = this.character
      return character ? character.expiredAt : null
    },
    tags() {
      const character = this.character
      return (
        (character &&
          character.tags &&
          character.tags.split(',')) ||
        []
      )
    },
    expects() {
      const character = this.character()
      return (
        (character &&
          character.text2 &&
          character.text2.split('· ')) || []
      ).filter(e => e.length)
    },
    limitations() {
      const character = this.character
      return (
        (character &&
          character.text3 &&
          character.text3.split('· ')) ||
        []
      ).filter(e => e.length)
    }
  },
  mounted() {
    const rootEl = this.$refs.rootEl.getBoundingClientRect()
    this.width = rootEl.width + 64
    // this.showInvalidCodeDialog = true;

    const { t, s } = this.$route.query
    this.payPlan = t
    if (s === 'success') {
      if (t) {
        this.showPaySuccessPopup = true
        this.$store.commit('setPendingPayPlan', null)
      }
    } else if (s === 'cancel') {
      this.showPayCancelPopup = true
    }

  },
  methods: {
    onCompanyConflict() {
      this.onClickRedeemCode(true)
    },
    onViewReceipt() {
      this.$router.replace({ path: '/', query: { c: 'payment-history-detail', o: this.payPlan.transaction } })
    },
    onGoHome() {
      this.$router.replace('/')
    },
    onTryPay() {
      if (this.payPlan && this.payPlan.priceId) {
        this.$refs.plans.retryPay(this.payPlan.priceId)
      }
    },
    onTry() {
      this.redeemCode = ''
    },
    onClickRedeemCode(force = false) {
      if (this.redeemCode && this.redeemCode.length) {
        this.performRedeem(force).then((res) => {
          this.business = res
          const id = res.product.id
          const characters = this.$store.state.characters.filter(e => e.id === id)
          if (characters.length) {
            this.paidCharacter = characters[0]

            const c = Object.assign({}, this.paidCharacter)
            c.own = true
            c.expiredAt = this.business.expiredAt

            this.$store.commit('character', c)
            this.$store.commit('setCharacter', c)
          }
          this.showBusinessDialog = true
          this.$store.dispatch('fetchMembership')
        }).catch((err) => {
          if (err.response) {
            const resp = err.response.data
            const { data, code } = resp
            // alert(JSON.stringify(err.response))
            if (`${code}` === '5007') {
              this.showAlreayInUseDialog = true
            } else if (`${code}` === '3006') {
              const dst = this.$store.state.characters.filter((e) => e.id === data.expect)
              if (dst.length) {
                this.expectName = dst[0].name
              }
              this.showNotMatchDialog = true
            } else if (`${code}` === '5006') {
              this.showInvalidCodeDialog = true
            } else if (`${code}` === '3007') {
              this.showCompanyConflictDialog = true
              this.expectName = data && data.company
            }
            // console.log('Response body:', err.response);
          } else {
            alert(err)
          }
        })
      }
    },
    async performRedeem(force) {
      const resp = await this.$api.redeem(this.redeemCode, this.character.id, force)
      const { success, data } = resp.data;
      if (success) {
        return data;
      } else {
        const err = new Error("Redeem failed");
        err.response = resp;
        throw err;
      }
    },
    onBack() {
      this.$router.replace("/")
    },
    onClickCharacter() {
      this.$store.commit('setCharacter', this.character)
    }
  }
}
