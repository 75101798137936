import { render, staticRenderFns } from "./PaymentHistory.vue?vue&type=template&id=fbcaed9c&scoped=true"
import script from "./PaymentHistory.vue?vue&type=script&lang=js"
export * from "./PaymentHistory.vue?vue&type=script&lang=js"
import style0 from "./PaymentHistory.vue?vue&type=style&index=0&id=fbcaed9c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbcaed9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleBar: require('/opt/render/project/src/components/TitleBar.vue').default,DividerLine: require('/opt/render/project/src/components/DividerLine.vue').default,LoadingSpinner: require('/opt/render/project/src/components/LoadingSpinner.vue').default})
