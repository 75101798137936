
export default {
  name: 'ListTile',
  props: {
    prefix: String,
    text: String,
  },
  data() {
    return {}
  },
}
