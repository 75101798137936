export default function({ $axios, $fire, redirect }, inject) {
  // Create a custom axios instance
  const baseURL = 'https://api.emoexai.com'
  const conf = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'platform': 'Web'
    }
  }


  const api = $axios.create(conf)
  // 请求拦截器
  api.onRequest(async config => {
    let idToken
    let uid
    if ($fire.auth.currentUser) {
      uid = $fire.auth.currentUser.uid
      if ($fire.auth.currentUser.getIdToken) {
        idToken = await $fire.auth.currentUser.getIdToken(true)
      }
    }
    // console.log("network request uid:", uid, ",idToken:", idToken, $fire.auth.currentUser);
    if (idToken) {
      config.headers['EMOEX-TOKEN'] = idToken
      config.headers.uid = uid
    }
    return config
  })

// 响应拦截器
  api.onResponse(response => {
    // 对响应数据进行处理
    // console.log("inteceptor response:", response);
    return response
  })

// 错误拦截器
  api.onError(error => {
    // 对请求错误做一些处理
    if (error.response) {
      const { status } = error.response
      if (status === 401) {
        redirect('/?login=true')
      }
    }
    // console.log("inteceptor error:", error.response);
    // console.log("inteceptor error: end");
    return Promise.reject(error)
  })

  const ApiService = {
    userAuth: (token, tz) => {
      const encodedData = `idToken=${token}&timezone=${tz}&device=2`
      return api.post('/user/auth', encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    chat: async (message, productId, free = false) => {
      let idToken
      const user = $fire.auth.currentUser
      const uid = user && user.uid
      if (user && user.getIdToken) {
        idToken = await $fire.auth.currentUser.getIdToken(true)
      }

      const formData = new URLSearchParams()
      formData.append('message', message)
      formData.append('productId', productId)

      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/chat/${free ? 'freeAssist' : 'assist'}`, {
          method: 'POST',
          headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            uid,
            'EMOEX-TOKEN': idToken
          },
          body: formData
        }).then((response) => resolve(response)).catch((err) => reject(err))
      })
      // return api.post('/chat/', encodedData, {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   },
      //   responseType: 'stream'
      // });
    },
    audioChat: (url) => api.post('/chat/audio', { url }),
    updateProfile: (ageRange, gender) => {
      const encodedData = `ageRange=${ageRange}&gender=${gender}`
      return api.post('/user/profile', encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    getProfile: () => api.get('/user/profile'),
    getFeelLogs: (page, limit) => api.get('/chat/feelLogs', { params: { page, limit } }),
    getChatHistory: (date, page, limit) => api.get('/chat/history', { params: { date, page, limit } }),
    getMemberShipDetail: () => api.get('/user/membership'),
    putChatTag: (chatIdPath, chatId, tags) => api.put(`/chat/${chatIdPath}/tag`, null, { params: { chatId, tags } }),
    deleteFeelLog: (id) => api.delete(`/chat/feelLog/${id}`),
    deleteFeelLogs: (ids) => {
      const encodedData = 'ids=' + ids.join('&ids=')
      return api.delete('/chat/feelLogs?' + encodedData)
    },
    tts: (text) => api.post('/chat/tts', { text }),
    downloadResource: (path) => api.get(path, { responseType: 'stream' }),
    putAppRate: (score) => api.put('/rate/app', null, { params: { score } }),
    acknowledge: (productId, purchaseToken, packageName) => api.post('/googleplay/acknowledge', {
      productId,
      purchaseToken,
      packageName
    }),
    getPaymentHistory: (page, limit) => api.get('/transaction/list', { params: { page, limit } }),
    convertTextToSpeech: (requestBody) => api.post('https://api.openai.com/v1/audio/speech', requestBody, { responseType: 'stream' }),
    fetchProductList: () => api.get('/product/list'),
    postFeedback: (productId, question, opinion) => {
      const encodedData = `question=${question}&opinion=${opinion}`
      return api.post(`/feedback/product/${productId}`, encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    redeem: (code, productId, force = false) => {
      const encodedData = `code=${code}&productId=${productId}&force=${force}`
      return api.post('/code/redeem', encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    getPriceList: (productId) => {
      return api.get('/stripe/price/list', { params: { productId } })
    },
    createSession: (priceId, successUrl, cancelUrl) => {
      const encodedData = `priceId=${priceId}&successUrl=${successUrl}&cancelUrl=${cancelUrl}`
      return api.post('/stripe/createSession', encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    getSystemBanner: () => api.get('/system/banner'),
    querySession: (sessionId) => api.get(`/stripe/session/${sessionId}`),
    postSummaryFeedback: (feelLogId, likeSuggestion, improveSituation) => {
      const encodedData = `likeSuggestion=${likeSuggestion}&improveSituation=${improveSituation}`
      return api.post(`/feedback/feelLog/${feelLogId}`, encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    }
  }

// Inject to context as $api
  inject('api', ApiService)
}
