import { render, staticRenderFns } from "./MyProfile.vue?vue&type=template&id=121b904f&scoped=true"
import script from "./MyProfile.vue?vue&type=script&lang=js"
export * from "./MyProfile.vue?vue&type=script&lang=js"
import style0 from "./MyProfile.vue?vue&type=style&index=0&id=121b904f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "121b904f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleBar: require('/opt/render/project/src/components/TitleBar.vue').default,ListTile: require('/opt/render/project/src/components/ListTile.vue').default,SwitchButton: require('/opt/render/project/src/components/SwitchButton.vue').default,EmoexPopup: require('/opt/render/project/src/components/EmoexPopup.vue').default})
