
import { UserMixin } from '@/mixins/UserMixin'
import GradientButton from '@/components/GradientButton.vue'
import MyAvatar from '@/components/MyAvatar.vue'
import BusinessUserPopup from '~/components/popup/BusinessUserPopup.vue'
import SvgChevronRight from '~/components/svgs/SvgChevronRight.vue'
import SvgPencilSquare from '~/components/svgs/SvgPencilSquare.vue'

export default {
  name: 'UserAuthorized',
  components: { MyAvatar, GradientButton, BusinessUserPopup, SvgChevronRight, SvgPencilSquare },
  mixins: [UserMixin],
  data() {
    return {
      // isBusiness: true,
      showBusinessDialog: false,
      business: null,
      color: '#2F374B'
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
    canEdit() {
      return !~['my-profile', 'edit-my-profile'].indexOf(this.$route.query.c)
    },
    businessList() {
      const { memberships } = this.$store.state.membership || { 'memberships': [] }
      // const dst = memberships.filter((e) => e.business);
      // const list = [];
      // list.push(dst[0])
      // console.log("lll=>", list.length);
      // return list;
      return memberships.filter((e) => e.business)
    },
    isBusiness() {
      return this.businessList.length > 0
    },
    onlyBusiness() {
      return this.isBusiness ? this.businessList[0] : null
    }
  },
  methods: {
    onEditUser() {
      this.$router.push({ path: '/profile/edit' })
    },
    onBusinessClick(item) {
      this.business = item
      this.showBusinessDialog = true
    },
    goSignup() {
      this.$router.push({ path: '/account/SignUp' })
    },
    format(timestamp) {
      const date = this.$dayjs(timestamp)
      const formattedDate = date.format('DD MMM YYYY')
      return formattedDate
    }
  }
}
