import { render, staticRenderFns } from "./HitFreeLimitPopup.vue?vue&type=template&id=5a94a111&scoped=true"
import script from "./HitFreeLimitPopup.vue?vue&type=script&lang=js"
export * from "./HitFreeLimitPopup.vue?vue&type=script&lang=js"
import style0 from "./HitFreeLimitPopup.vue?vue&type=style&index=0&id=5a94a111&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a94a111",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OutlineButton: require('/opt/render/project/src/components/OutlineButton.vue').default,GradientButton: require('/opt/render/project/src/components/GradientButton.vue').default})
