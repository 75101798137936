
export default {
  name: 'ExpandableItem',
  props: {
    subscribed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''  
    },
    expiredAt: Object,
  },
  data() {
    return {
      expanded: false,
    }
  },
  mounted(){
    if(!this.subscribed) {
      this.expanded = true;
    } else {
      this.expanded = false;
    }
  },
  methods: {
    format(timestamp) {
      const date = this.$dayjs(timestamp);
      const formattedDate = date.format('DD MMM YYYY');
      return formattedDate;
    },
    expand() {
      this.expanded = !this.expanded
      // console.log('expanded:', this.expanded)
    },
    close(){
      this.expanded = false;
    }
  },
}
