
const arrowLeftImg = require('@/static/arrow-left.svg')
const arrowLeftBlackImg = require('@/static/arrow-left-black.svg')
export default {
  name: 'TitleBar',
  props: {
    prefix: String,
    text: String,
    suffix: String,
  },
  computed: {
    isDark() {
      return this.$store.state.theme === 'dark'
    },
    backImg() {
      return this.$listeners['title:back'] ? this.prefix || (this.isDark ? arrowLeftImg : arrowLeftBlackImg): null
    },
  },
  methods: {
    onClick() {
      this.$emit("click:suffix")
    }
  }
}
