export const state = () => ({
  currentChattingCharacter: {
    id: 'asst_4qvcMuiHFUbDVT05XA1fNt2E'
  },
})

export const mutations = {
  setCurrentChattingCharacter(state, character) {
    state.currentChattingCharacter = character
  },
}
