
import GradientButton from '@/components/GradientButton.vue'
import TitleBar from '@/components/TitleBar.vue'

export default {
  name: 'EditMyProfile',
  comments: { TitleBar },
  components: { GradientButton },
  data() {
    return {
      imageUrl: null,
      uploading: false,
      uploadError: null,
      uploadProgress: 0,
      file: null,
      nickName: null,
    }
  },
  computed: {
    displayName() {
      return this.$store.state.user && this.$store.state.user.displayName
    },
    avatar() {
      return (
        this.imageUrl ||
        (this.$store.state.user && this.$store.state.user.photoURL)
      )
    },
    genders() {
      return this.$store.state.genders
    },
    ages() {
      return this.$store.state.ages
    },
    genderIndex() {
      return this.$store.state.profile.gender
    },
    ageIndex() {
      return this.$store.state.profile.ageRange
    },
  },
  mounted() {
    this.nickName = this.displayName
    if (this.$store.state.profile.mail === '--') {
      this.$api.getProfile().then((resp) => {
        const { success, data } = resp.data
        if (success) {
          const genderIndex = data.gender
          this.$store.commit('setGenderIndex', genderIndex)

          const rangeIndex = data.ageRange
          this.$store.commit('setAgeRange', rangeIndex)
        }
      })
    }
  },
  methods: {
    async saveProfile() {
      const profileParams = {}
      if (this.nickName && this.nickName.length) {
        profileParams.displayName = this.nickName
      }
      if (this.imageUrl && this.imageUrl.length) {
        profileParams.photoURL = this.imageUrl
      }
      const canSubmit = Object.keys(profileParams).length

      const resp = await this.$api.updateProfile(
        this.ageIndex,
        this.genderIndex
      )
      const { success, message } = resp.data
      if (success) {
        if (canSubmit) {
          this.updateFirebaseProfile(profileParams)
        } else {
          this.onBack()
        }
      } else {
        alert(message)
      }
    },
    updateFirebaseProfile(profileParams) {
      const user = this.$fire.auth.currentUser
      // 更新用户资料中的头像URL
      user
        .updateProfile(profileParams)
        .then(() => {
          this.$store.commit('setUser', user)
          // 更新成功
          console.log(
            'User profile updated successfully with new photoURL:',
            this.imageUrl
          )
          this.onBack()
        })
        .catch((error) => {
          // 更新失败，处理错误
          console.error('Error updating user profile:', error)

          alert(error)
        })
    },
    uploadFile() {
      this.$refs.uploader.click()
    },
    onUpload(e) {
      const { files } = e.target
      if (files.length && files[0]) {
        this.file = files[0]
        this.imageUrl = URL.createObjectURL(this.file)
        this.updateUserProfile(this.file)
      }
    },
    clickAge(item, index) {
      this.$store.commit('setAgeRange', index)
    },
    clickGender(item, index) {
      this.$store.commit('setGenderIndex', index)
    },
    onBack() {
      this.$router.go(-1)
    },
    updateUserProfile(file) {
      // 获取当前已登录用户的引用
      const user = this.$fire.auth.currentUser

      // 如果用户上传了新头像文件，则先将新头像上传到 Firebase Storage
      if (file) {
        const storageRef = this.$fire.storage.ref()
        const fileName = this.$dayjs().valueOf()
        const uploadTask = storageRef
          .child(`user_avatars/${user.uid}/${fileName}`)
          .put(file)

        this.uploading = true
        this.uploadError = null

        // 监听上传任务状态
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.uploadProgress = Math.floor(progress)
          },
          (error) => {
            console.error('Error uploading file:', error)
            this.uploadError = 'Upload failed. Please try again.'
            this.uploading = false
          },
          () => {
            // 上传完成，获取头像的下载URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.imageUrl = downloadURL
              this.uploading = false
            })
          }
        )
      }
    },
  },
}
