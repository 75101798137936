import { render, staticRenderFns } from "./desktop.vue?vue&type=template&id=7b5cbfaf"
import script from "./desktop.vue?vue&type=script&lang=js"
export * from "./desktop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LeftSideBar: require('/opt/render/project/src/components/LeftSideBar.vue').default,SideBar: require('/opt/render/project/src/components/SideBar.vue').default})
