
import SvgCharacters from '~/components/svgs/SvgCharacters.vue'
import SvgFeellogs from '~/components/svgs/SvgFeellogs.vue'
import SvgMyProfile from '~/components/svgs/SvgMyProfile.vue'
import SvgEmoexInfo from '~/components/svgs/SvgEmoexInfo.vue'
import HoverStretch from '~/components/HoverStretch.vue'

const darkLogo = require('~/static/logo-white.svg')
const darkHovered = require('~/static/logo-rect.svg')
const lightLogo = require('~/static/logo-green.svg')
const lightHovered = require('~/static/logo-white-rect.svg')

export default {
  name: 'LeftSideBar',
  components: {
    SvgMyProfile,
    SvgCharacters,
    SvgFeellogs,
    SvgEmoexInfo,
    HoverStretch,
  },
  data() {
    return {
      darkLogo,
      darkHovered,
      lightLogo,
      lightHovered,
      isHovered: false,
      routes: ['', 'feellogs', 'profile'],
      routeNames: ['index', 'feellogs', 'profile'],
    }
  },
  computed: {
    isDark() {
      return this.$store.state.theme === 'dark'
    },
    selectedIndex() {
      const c = this.$route.name
      return this.routeNames.indexOf(c)
    },
  },
  mounted() {},
  methods: {
    nav(index) {
      if(index === 3){
        window.open('https://www.emoexai.com/', '_blank');
      } else {
        this.$router.push({
          path: `/${this.routes[index]}`,
          // query: { c: this.routes[index], r: Math.floor(Math.random() * 100) },
        })
      }
    },
    fillColor(index) {
      const isDark = this.$store.state.theme === 'dark';
      return this.selectedIndex === index ? isDark ? 'white': '#161618' : '#95A2B5'
    },
    handleMouseEnter() {
      this.isHovered = true
    },
    handleMouseLeave() {
      this.isHovered = false
    },
  },
}
