
import EmoexPopup from '@/components/EmoexPopup.vue'
export default {
  name: 'DeleteFeellogPopup',
  components: { EmoexPopup },
  props: ['value'],
  methods: {
    onDeletePopClose() {
       this.$emit('input')
    },
    onDeleteNegative() {
       this.$emit('negative'); 
    },
    onDeletePositive() {
       this.$emit('positive');
    },
  }
}
