import { render, staticRenderFns } from "./ProCharacterDetail.vue?vue&type=template&id=248a5983&scoped=true"
import script from "./ProCharacterDetail.vue?vue&type=script&lang=js"
export * from "./ProCharacterDetail.vue?vue&type=script&lang=js"
import style0 from "./ProCharacterDetail.vue?vue&type=style&index=0&id=248a5983&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248a5983",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleBar: require('/opt/render/project/src/components/TitleBar.vue').default,GradientButton: require('/opt/render/project/src/components/GradientButton.vue').default,MemberPlans: require('/opt/render/project/src/components/MemberPlans.vue').default,ExpandableItem: require('/opt/render/project/src/components/ExpandableItem.vue').default})
