import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=012ffea0&scoped=true"
import script from "./MainLayout.vue?vue&type=script&lang=js"
export * from "./MainLayout.vue?vue&type=script&lang=js"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=012ffea0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012ffea0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/opt/render/project/src/components/LoadingSpinner.vue').default})
