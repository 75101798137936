
import MainLayout from '~/layouts/MainLayout'
import { UserMixin } from '~/mixins/UserMixin'
import LeftSideBar from '~/components/LeftSideBar.vue'
import SideBar from '~/components/SideBar.vue'

export default {
  name: 'IndexPage',
  components: { SideBar, LeftSideBar, MainLayout },
  mixins: [UserMixin],
  data() {
    return {
      showVerifyDialog: false,
      showLoginDialog: false
    }
  },
  computed: {
    showFeellogs() {
      return this.$store.state.feellog != null && this.$route.query.c === 'feellogs'
    }
  },
  watch: {
    '$store.state.idToken': {
      handler(newVal, oldVal) {
        if (oldVal !== newVal && newVal) {
          // const user = this.$fire.auth.currentUser;
          this.userAuth(newVal)
          this.$store.dispatch('fetchMembership')
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('getSystemBanner', this.$api)
  }
}
