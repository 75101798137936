import { inject } from "vue";

export default ({ app }, inject) => {
    // 引入 gtag.js 脚本
    const mid = 'G-FC78SEGXGL';
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${mid}`
    document.head.appendChild(script)
  
    function gtag() {
        window.dataLayer.push(arguments)
    }

    // 初始化 Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      
      gtag('js', new Date())
      gtag('config', mid)
    
    }
    inject('ga', gtag)
  }
  