
import GradientButton from '@/components/GradientButton.vue'
import BasePopup from '@/components/popup/BasePopup.vue'

export default {
  components: { GradientButton, BasePopup },
  props: ['character', 'value'],
  data() {
    return {
      btnLabel: "Let's Chat"
    }
  },
  methods: {
      onClose() {
        this.$emit('input')
      },
      chat() {
        this.onClose();
        const c = Object.assign({}, this.character)
        c.own = true;
        if(this.character)this.$store.commit("setCharacter", c)
        this.$router.replace('/');
      }
  }  
}
