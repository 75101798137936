import { render, staticRenderFns } from "./PaymentSucceedPop.vue?vue&type=template&id=7367c94d&scoped=true"
import script from "./PaymentSucceedPop.vue?vue&type=script&lang=js"
export * from "./PaymentSucceedPop.vue?vue&type=script&lang=js"
import style0 from "./PaymentSucceedPop.vue?vue&type=style&index=0&id=7367c94d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7367c94d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmoexPopup: require('/opt/render/project/src/components/EmoexPopup.vue').default})
