
export default {
  name: 'OutlineButton',
  props: ['text', 'gradient'],
  methods: {
    click() {
       this.$emit("click")
    }
  }
}
