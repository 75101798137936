
import BasePopup from '@/components/popup/BasePopup.vue'

export default {
  name: 'BusinessUserPopup',
  components: { BasePopup },
  props: ['value', 'business'],
  methods: {
    onClose() {
      this.$emit('input')
    },
    format(timestamp) {
      const date = this.$dayjs(timestamp);
      const formattedDate = date.format('DD MMM YYYY');
      return formattedDate;
    }
  },
}
