
export default {
  name: 'SwitchButton',
  props: {
    isActive: {
        type: Boolean,
        default: false,
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.isActive)
    }
  }
}
