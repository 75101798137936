
import EmoexPopup from '@/components/EmoexPopup.vue'
export default {
  name: 'RedeemCompanyConflictWraningPopup',
  components: { EmoexPopup },
  props: ['value', 'name'],
  methods: {
    onDeletePopClose() {
       this.$emit('input')
    },
    onDeleteNegative() {
       this.$emit('negative'); 
    },
    onDeletePositive() {
       this.$emit('positive');
    },
  }
}
