import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=7ae2bfda"
import script from "./SideBar.vue?vue&type=script&lang=js"
export * from "./SideBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAuthorized: require('/opt/render/project/src/components/UserAuthorized.vue').default})
