
import ListTile from '@/components/ListTile.vue'
import TitleBar from '@/components/TitleBar.vue'
import EmoexPopup from '@/components/EmoexPopup.vue'
import SvgPencilSquare from '@/components/svgs/SvgPencilSquare.vue'
import CancelSubscriptionPopup from '@/components/popup/CancelSubscriptionPopup.vue'

// const myProfile = require('@/static/pencil-square.svg')
const changePwdIcon = require('@/static/key-solid.svg')
const darkModeIcon = require('@/static/theme.svg')
const signOutIcon = require('@/static/arrow-right-on-rectangle.svg')
const deleteIcon = require('@/static/delete-solid.svg')
const paymentIcon = require('@/static/receipt-solid.svg')
const refundPolicyIcon = require('@/static/submissions-solid.svg')
const cancelSubscriptionIcon = require('@/static/minus-circle-solid.svg')

export default {
  name: 'MyProfile',
  components: { TitleBar, ListTile, EmoexPopup, SvgPencilSquare, CancelSubscriptionPopup },
  data() {
    return {
      changePwdIcon,
      // myProfile,
      darkModeIcon,
      signOutIcon,
      deleteIcon,
      paymentIcon,
      refundPolicyIcon,
      cancelSubscriptionIcon,
      accountDetails: [
        { key: 'email', name: 'Email', value: '--' },
        { key: 'gender', name: 'Gender', value: '--' },
        { key: 'ageRange', name: 'Age group', value: '--' },
        { key: 'referralCode', name: 'Referral code', value: '--' }
      ],
      showDeleteDialog: false,
      showSignOutDialog: false,
      showCancelSubsDialog: false
    }
  },
  computed: {
    canBack() {
      return this.$listeners['title:back']
    },
    isDarkTheme() {
      return this.$store.state.theme === 'dark'
    },
    canResetPassword() {
      const user = this.$store.state.user
      return user && user.providerData.filter((item) => {
        return item.providerId === 'password'
      }).length > 0
    }
  },
  mounted() {
    this.getProfile()
      .then((data) => {
        this.accountDetails.forEach((item, index) => {
          if (item.key === 'gender') {
            const index = data[item.key]
            if (index >= 0) {
              item.value = this.$store.state.genders[index]
              this.$store.commit('setGenderIndex', index)
            }
          } else if (item.key === 'ageRange') {
            const index = data[item.key]
            if (index >= 0) {
              item.value = this.$store.state.ages[index]
              this.$store.commit('setAgeRange', index)
            }
          } else {
            item.value = data[item.key]
          }
        })
      })
    // .catch((err) => {
    //  // alert(err)
    // })
  },
  methods: {
    onPaymentClick() {
      if (this.$store.state.isMobile) {
        this.$router.push({ path: '/mobile/MobilePaymentHistory' })
      } else {
        this.$router.push({ path: '/', query: { c: 'payment-history' } })
      }
    },
    clearUser() {
      localStorage.removeItem('agreement')
      this.$store.commit('clearAll')
      this.$router.replace({ path: '/' })
    },
    onSignOutNegative() {

    },
    onSignOutPositive() {
      this.$fire.auth.signOut().then(() => {
        this.clearUser()
      }).catch((error) => {
        alert(error)
      })
    },
    onSignOutClose() {
      this.showSignOutDialog = false
    },
    onSignOutClick() {
      this.showSignOutDialog = true
    },
    onDeleteClick() {
      this.showDeleteDialog = true
    },
    onDeletePopClose() {
      this.showDeleteDialog = false
    },
    onDeleteNegative() {
    },
    onDeletePositive() {
      this.$fire.auth.currentUser.delete().then(() => {
        this.clearUser()
      }).catch((error) => {
        alert(error)
      })
    },
    onResetPassword() {
      this.$router.push({ path: '/account/ChangePassword' })
    },
    getProfile() {
      return new Promise((resolve, reject) => {
        this.$api
          .getProfile()
          .then((result) => {
            const { success, data, message } = result.data
            // console.log("result:", result, ",", success, ",", result.data);
            if (success) {
              resolve(data)
            } else {
              reject(message)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    onBack() {
      this.$router.go(-1)
    },
    clickSuffix() {
      this.$router.push({ path: '/profile/edit' })
    },
    onChangeTheme(isActive) {
      this.$store.commit('changeTheme')
      localStorage.setItem('theme', this.$store.state.theme)
    },
    onCancelSubscriptionClick() {
      this.showCancelSubsDialog = true
    },
    onCancelSubsAction() {
      window.open('https://billing.stripe.com/p/login/test_28o7tMdxDbVJ7Di7ss', '_blank')
    },
    onClickRefund() {
      window.open('https://www.emoexai.com/refund-policy', '_blank')
    }
  }
}
