
export default {
  name: 'HoverStretch',
  props: {
    isHovered: {
      type: Boolean,
      default: false,
    },
    name: String,
    selected: Boolean,
    gradientText: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onTap() {
      this.$emit('click')
    },
  },
}
