
import BasePopup from '@/components/popup/BasePopup.vue'
import CheckBox from '@/components/CheckBox.vue'
import GradientButton from '@/components/GradientButton.vue'
export default {
    name: "UserPermissionPopup",
    components: { BasePopup, CheckBox, GradientButton },
    props: ['value'],
    data() {
        return {
            checkedAgreement: true,
            agreementHighlight: false,
            checkedRcvEmail: true
        }
    },
    computed: {
      isDark() {
        return this.$store.state.theme === 'dark'
      }
    },
    methods: {
      onClose() {
        this.$emit('input')
      },
      clickAgreement(){
        if(!this.checkedAgreement) {
          this.agreementHighlight = true;
          return;
        }
        localStorage.setItem("agreement", JSON.stringify({
            checkedAgreement: this.checkedAgreement,
            checkedRcvEmail: this.checkedRcvEmail
        }))  
        this.agreementHighlight = false;
        this.onClose();
      }
    }
}
