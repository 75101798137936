
import DividerLine from '@/components/DividerLine.vue'
import TitleBar from '@/components/TitleBar.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'PaymentHistory',
  components: { TitleBar, DividerLine, LoadingSpinner },
  data(){
    return {
      loading: false,
      page: -1,
      noMore: false,
      list: []
    }
  },
  mounted(){
    this.getPaymentHistory()
    
    const element = this.$refs.paymentHistories;
    element.addEventListener("scroll", (e)=>{
      console.log("wheel:", e);
      // 判断是否滚动到了底部
      if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
          // 到达了底部
          this.getPaymentHistory()
      }
    });
  },
  methods: {
     formatPrice(str) {
      const formattedStr = (parseInt(`${str}`)/1000).toLocaleString('en-US', {
        maximumFractionDigits: 0, // 不显示小数点和小数位
        thousandsSeparator: ',' // 使用逗号作为千位分隔符
      });
      return formattedStr;
    },
    showDivider(pre, cur) {
      return this.$dayjs(pre.billingAt).year() !== this.$dayjs(cur.billingAt).year()
    },
    async getPaymentHistory(){
      if(this.loading || this.noMore) {
        return;
      }
      this.loading = true;
      this.page += 1;
      const limit = 20;
      try{
       const resp = await this.$api.getPaymentHistory(this.page, limit);
       const {success, data, message} = resp.data;
          if(success) {
            this.list = this.list.concat(data);
            this.noMore = !data.length
          } else {
            alert(message)
          }
      } finally {
        this.loading = false;
      }
    },
    goDetail(item) {
      this.$router.push({path: "/", query: {c: "payment-history-detail", o: encodeURIComponent(JSON.stringify(item))}});
    },
    back() {
        this.$router.go(-1);
    },
  },
}
