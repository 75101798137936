
export default {
  name: 'GradientButton',
  props: {
    type: {
      type: String,
      default: 'pink',
      required: true,
    },
    text: {
        type: String,
        default: '',
        required: true,
    }
  },
  methods: {
    click() {
      this.$emit("click")
    }
  }
}
