
import LoadingSpinner from '~/components/LoadingSpinner.vue'
import HitFreeLimitPopup from '~/components/popup/HitFreeLimitPopup.vue'
import UserPermissionPopup from '~/components/popup/UserPermissionPopup.vue'

export default {
  name: 'MainLayout',
  components: { LoadingSpinner, UserPermissionPopup, HitFreeLimitPopup },
  data() {
    return {
      showRight: true,
      loading: true,
      showPermissionPopup: false,
      showLoginPopup: false,
      innerHeight: '100vh',
    }
  },
  watch: {
    '$route.query.c': {
      handler(newVal, oldVal) {
        this.showRight = true
        if (newVal !== oldVal) this.$store.commit('setFeellogs', null)
      },
      immediate: true,
      deep: true,
    },
    '$route.query.login': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          setTimeout(() => {
            this.showLoginPopup = true
          }, 500)
        }
        console.log(
          'query:',
          newVal,
          oldVal,
          ',showLogiNpopup:',
          this.showLoginPopup
        )
      },
      immediate: true,
      deep: true,
    },
    '$store.state.theme': {
      handler(newVal, oldVal) {
        // console.log("watch...theme>>>", newVal, ", old:", oldVal);
        if (!this.loading) this.changeTheme(newVal)
        // if(newVal !== oldVal)localStorage.setItem('theme', newVal)
      },
      immediate: true,
      deep: true,
    },
    '$route.query.p': {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          const p = decodeURIComponent(newVal)
          if (!p) {
            return
          }
          const { i } = JSON.parse(p)
          try {
            const resp = await this.$api.fetchProductList()
            const { success, data } = resp.data
            if (!success) {
              return
            }
            const characters = data.filter((e) => e.id === i)
            if (characters.length) {
              setTimeout(() => {
                this.$router.replace({ path: `/${i}` })
              }, 500)
            }
          } catch (e) {
            console.log('error:', e)
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // '$route.query.session_id': {
    //   async handler(newVal, oldVal) {
    //     if (newVal !== oldVal && newVal) {
    //       try {
    //         const resp = await this.$api.querySession(newVal)
    //         const { success, data, message } = resp.data
    //         if (success) {
    //           if (data.length) {
    //             const transaction = data[0]
    //             // const {s, t, i} = JSON.parse(p)
    //             // const i = transaction.productId;
    //             const s = 'success'
    //             const t = {
    //               amount: transaction.price,
    //               currency: transaction.currency,
    //               priceId: transaction.priceId,
    //               transaction: encodeURIComponent(JSON.stringify(transaction)),
    //             }
    //             this.handlePaymentCallback(s, t, transaction.productName)
    //           } else {
    //             alert('Query failed.')
    //           }
    //         } else {
    //           alert(message)
    //         }
    //       } catch (err) {
    //         const { data } = err.response.data
    //         const { priceId, productId /** sessionState**/ } = data
    //         const s = 'cancel'
    //         const t = {
    //           priceId,
    //         }
    //         this.handlePaymentCallback(s, t, productId)
    //       }
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    const theme = localStorage.getItem('theme') || 'dark'
    this.$store.commit('setTheme', theme)

    const visible = localStorage.getItem('downloadBannerVisible') || 1
    this.$store.commit('setDownloadBannerVisible', parseInt(`${visible}`))

    this.$nextTick(() => {
      this.changeTheme(theme)
      this.loading = false
      this.$fire.auth.onAuthStateChanged((user) => {
        if (!user) this.showAgreement()
      })
    })
  },
  methods: {
    handleResize() {
      this.innerHeight = `${window.innerHeight}px`
    },
    // async handlePaymentCallback(s, t, identifier) {
    //   try {
    //     const resp = await this.$api.fetchProductList()
    //     const { success, data } = resp.data
    //     if (success) {
    //       const characters = data.filter(
    //         (e) => e.name === identifier || e.id === identifier
    //       )
    //       if (characters.length) {
    //         if (s === 'success') {
    //           this.$store.commit('setCharacter', characters[0])
    //         }
    //         setTimeout(() => {
    //           this.$router.replace({ path: `/character/${identifier}` })
    //         }, 500)
    //       }
    //       // this.$router.replace({path: "/", query})
    //     }
    //   } catch (e) {}
    // },
    handleMouseLeave() {
      // console.log(".....", this.showRight);
      this.showRight = !this.showRight
    },
    showRightSideBar() {
      this.showRight = true
    },
    closeRightSideBar() {
      this.showRight = false
    },
    changeTheme(theme) {
      const dark = {
        '--nn0': '#161618',
        '--nn1': '#1E1E23',
        '--nn2': '#2F374B',
        '--nn3': '#95A2B5',
        '--nn4': '#E0E7EC',
        '--nn5': '#F6FAFF',
        '--nn6': '#FFFFFF',
      }
      const light = {
        '--nn0': '#FFFFFF',
        '--nn1': '#F6FAFF',
        '--nn2': '#E0E7EC',
        '--nn3': '#95A2B5',
        '--nn4': '#2F374B',
        '--nn5': '#1E1E23',
        '--nn6': '#161618',
      }
      this.$nextTick(() => {
        if (theme === 'light') {
          Object.keys(light).forEach((key) => {
            document.documentElement.style.setProperty(key, light[key])
          })
        } else {
          Object.keys(dark).forEach((key) => {
            document.documentElement.style.setProperty(key, dark[key])
          })
        }
      })
    },
    showAgreement() {
      const didSetAgreement = localStorage.getItem('agreement')
      if (!didSetAgreement) {
        setTimeout(() => {
          this.showPermissionPopup = true
        }, 500)
      } else {
        //  checkedAgreement: true,
        //  agreementHighlight: false,
        //  checkedRcvEmail: false
        const obj = JSON.parse(didSetAgreement)
        this.showPermissionPopup = !obj.checkedAgreement
      }
    },
  },
}
