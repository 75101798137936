
import TitleBar from '@/components/TitleBar.vue'
import EmoexInput from '@/components/EmoexInput.vue'
import DividerLine from '@/components/DividerLine.vue'
import CheckBox from '@/components/CheckBox.vue'
import DeleteFeellogPopup from '@/components/popup/DeleteFeellogPopup.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SvgCheckboxOutline from '@/components/svgs/SvgCheckboxOutline.vue'
import SvgSearchOutline from '@/components/svgs/SvgSearchOutline.vue'

export default {
  name: 'FeelLogs',
  components: {
    TitleBar,
    EmoexInput,
    DividerLine,
    CheckBox,
    DeleteFeellogPopup,
    LoadingSpinner,
    SvgCheckboxOutline,
    SvgSearchOutline,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteDialog: false,
      checkMode: false,
      searchMode: false,
      keyword: '',
      viewIndex: -1,
      list: [
        // {checked: false, title: "Caption", "detail": "This box contains a summary of ur conversation & journal image."},
        // {checked: false, title: "Caption", "detail": "This box contains a summary of ur conversation & journal image."},
        // {checked: false, title: "Caption", "detail": "This box contains a summary of ur conversation & journal image."},
        // {checked: false, title: "Caption", "detail": "This box contains a summary of ur conversation & journal image."},
      ],
      loading: false,
      page: 0,
      noMore: false,
    }
  },
  computed: {
    characters() {
      return this.$store.state.characters
    },
  },
  watch: {
    '$store.state.deletedLogs': {
      handler(newVal, oldVal) {
        if (~newVal.length) {
          if (this.viewIndex >= 0) {
            const n = newVal.findIndex(
              (e) => e === this.$store.state.feellog.id
            )
            if (n >= 0) {
              this.$store.commit('setFeellogs', null)
              this.viewIndex = -1
            }
          }
          this.list = this.list.filter((el) => {
            const index = newVal.findIndex((e) => e === el.id)
            return index < 0
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getFeellogs()

    const element = this.$refs.feellogs
    element.addEventListener('scroll', (e) => {
      // console.log("wheel:", e);
      // 判断是否滚动到了底部
      if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
        // 到达了底部
        this.getFeellogs()
      }
    })
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(date).format('ddd, DD MMM YYYY')
    },
    onDelete() {
      const selected = this.list.filter((e) => e.checked).length
      this.showDeleteDialog = selected > 0
    },
    onSearch() {
      this.searchMode = !this.searchMode
    },
    onCheckChanged(index) {
      this.list[index].checked = !this.list[index].checked
    },
    onCheckMode() {
      this.checkMode = true
    },
    onCancelMode() {
      this.searchMode = false
      this.checkMode = false
      this.keyword = ''
      this.list.forEach((item, index, array) => {
        array[index].checked = false
      })
    },
    onSelectAll() {
      this.list.forEach((item, index, array) => {
        array[index].checked = true
      })
    },
    onDeletePopClose() {
      this.showDeleteDialog = false
    },
    onDeleteNegative() {},
    onDeletePositive() {
      const deletedList = this.list.filter((e) => e.checked).map((e) => e.id)
      this.$api.deleteFeelLogs(deletedList).then((resp) => {
        if (resp.data.success) {
          this.$store.commit('deleteFeellogs', deletedList)
          this.list = this.list.filter((e) => !e.checked)
        } else {
          alert(resp.data.message)
        }
      })
    },
    onClickFeelog(index) {
      this.viewIndex = index
      this.$store.commit('setFeellogs', Object.assign({}, this.list[index]))
      if (this.$device.isMobile) {
        this.$router.push({
          path: '/mobile/MobileFeellogDetail',
          query: { o: encodeURIComponent(JSON.stringify(this.list[index])) },
        })
      }
    },
    onBack() {
      this.$router.go(-1)
    },
    async getFeellogs() {
      if (this.loading || this.noMore) {
        return
      }
      try {
        this.loading = true
        this.page += 1
        const limit = 20
        const resp = await this.$api.getFeelLogs(this.page, limit)
        this.loading = false
        const { success, data, message } = resp.data
        if (success) {
          const dataList = data.map((e) => {
            const list = this.characters.filter((el) => el.id === e.assistantId)
            return {
              ...e,
              checked: false,
              title: list.length ? list[0].name : e.title,
            }
          })
          this.list = this.list.concat(dataList)
          this.noMore = !dataList.length
        } else {
          alert(message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
