
import TitleBar from '@/components/TitleBar.vue'
import GradientButton from '@/components/GradientButton.vue'
export default {
  name: 'PaymentHistoryDetail',
  components: { TitleBar, GradientButton },
  data() {
    return {
  //  transaction: {
  //     "id": 263,
  //     "channel": 3,
  //     "price": 252000,
  //     "currency": "nzd",
  //     "priceId": "price_1PCeBWEVhw43vCfl16cOJCTt",
  //     "productName": "Better-Sleep",
  //     "billingAt": "2024-05-14T02:32:40.000+00:00",
  //     "membershipStartAt": "2024-05-14T02:32:38.000+00:00",
  //     "membershipExpiredAt": "2024-11-14T02:32:38.000+00:00",
  //     "name": null,
  //     "background": null,
  //   },
    transaction: null
    
    }
  },
  computed: {
    tps(){
      return this.$store.state.subscriptionTps;
    },
  },
  mounted() {
    const o = this.$route.query.o
    if(o) {
      this.transaction = JSON.parse(decodeURIComponent(o))
    }
    if(this.transaction) {
      const diff = this.$dayjs(this.transaction.membershipExpiredAt).diff(this.$dayjs(this.transaction.membershipStartAt), 'month');
      const tp = this.tps.filter((e) => e.month === diff)[0];
      if(tp) {
        this.transaction.name = tp.name
        this.transaction.background = tp.background
      }
    }
    console.log("transaction:", this.transaction);
  },
  methods: {
    formatPrice(str) {
      const formattedStr = (parseInt(`${str}`)/1000).toLocaleString('en-US', {
        maximumFractionDigits: 0, // 不显示小数点和小数位
        thousandsSeparator: ',' // 使用逗号作为千位分隔符
      });
      return formattedStr;
    },
    downloadReceipt(){
      window.open("https://billing.stripe.com/p/login/test_28o7tMdxDbVJ7Di7ss", "_blank")
    },  
    onBack() {
     // this.$router.go(-1)
     this.$router.replace({path: "/", query: {c: "payment-history"}})
    },
  },
}
