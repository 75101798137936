import { mapMutations, mapActions } from 'vuex';

export const UserMixin = {
    computed: {
        user() {
          const user = this.$store.state.user
          return user;
        }
    },
    methods: {
        ...mapMutations([
            'setUser',
            'setIdToken'
        ]),
        ...mapActions([
             'fetchMembership'
        ]),
        userAuth(idToken) {
          const date = new Date()
          const timezoneOffset = date.getTimezoneOffset()
          const timezoneOffsetHours = -timezoneOffset / 60
          const timezone = timezoneOffsetHours > 0 ? `+${timezoneOffsetHours}` : timezoneOffsetHours
          this.$api.userAuth(idToken, encodeURIComponent(timezone))
        }
    }
}
