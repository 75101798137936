export default ({ app }, inject) => {
    // 引入 gtag.js 脚本
    const mid = 'AW-11389954451';
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${mid}`
    document.head.appendChild(script)
  
    function gtag() {
        window.dataLayer.push(arguments)
    }

    // 初始化 Google Ads
    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      
      gtag('js', new Date())
      gtag('config', mid, {
        'cookie_flags': 'SameSite=None; Secure', // 确保 cookie 设置为 SameSite=None 和 Secure
        'cookie_domain': '.emoexai.com',     // 设置为你的网站域名
        'send_page_view': true,                  // 确保发送页面视图
        'anonymize_ip': true,                    // 可选：匿名 IP
        'allow_google_signals': false,           // 可选：禁用 Google Signals
        'linker': {
            'domains': ['localhost','.emoexai.com','chat.emoexai.com', 'chat-test.emoexai.com'] // 跨域跟踪的域名列表
        }
      })

    }

    inject('ad', gtag)
  }
  