import { render, staticRenderFns } from "./LeftSideBar.vue?vue&type=template&id=b0e63aea&scoped=true"
import script from "./LeftSideBar.vue?vue&type=script&lang=js"
export * from "./LeftSideBar.vue?vue&type=script&lang=js"
import style0 from "./LeftSideBar.vue?vue&type=style&index=0&id=b0e63aea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0e63aea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HoverStretch: require('/opt/render/project/src/components/HoverStretch.vue').default})
