

import FeelLogs from '@/components/FeelLogs.vue'
import PaymentHistoryDetail from '~/components/PaymentHistoryDetail.vue'
import EditMyProfile from '@/components/EditMyProfile.vue'
import CharactersList from '@/components/CharactersList.vue'
import MyProfile from '@/components/MyProfile.vue'
import PaymentHistory from '@/components/PaymentHistory.vue'
import UserAuthorized from '@/components/UserAuthorized.vue'
import ProCharacterDetail from '@/components/ProCharacterDetail.vue'

export default {
  name: 'SideBar',
  components: { UserAuthorized },
  data() {
    return {}
  },
  // eslint-disable-next-line require-await
  computed: {
    currentComponent() {
      const { name } = this.$route
      if (name === 'feellogs') {
        return FeelLogs
      } else if (name === "profile") {
        return MyProfile
      } else if (name === "character-slug") {
        return ProCharacterDetail
      } else if (name === "profile-edit") {
        return EditMyProfile
      }

      const c = this.$route.query.c
      if (c === 'edit-my-profile') {
        return EditMyProfile
      } else if (c === 'my-profile') {
        return MyProfile
      } else if (c === 'payment-history') {
        return PaymentHistory
      } else if (c === 'payment-history-detail') {
        return PaymentHistoryDetail
      } else if (c === 'feellogs') {
        return FeelLogs
      } else if (c === 'pro-character') {
        return ProCharacterDetail
      }
      return CharactersList
    }
  }
}
